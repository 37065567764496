.mainWrapper {
  background: linear-gradient(180deg, #bfe8ff 0%, rgba(191, 232, 255, 0) 100%);
  @apply py-10 text-center;
}

.formContainer {
  @apply md:flex justify-center items-center mt-6;
}

.submit {
  @apply w-auto flex items-center rounded-full bg-zur-primary text-white no-underline py-3 px-7;
}
