.course {
  @apply flex flex-col lg:flex-row justify-around items-start mb-4 last:mb-8 p-4 gap-4 border rounded-lg border-gray-200;
}

.componentCourse {
  @apply border-t-0 border-x-0 last:border-b-0 rounded-none mb-0 last:mb-0;
}

.courseContent {
  @apply flex flex-col text-zur-primary order-2 lg:order-1 lg:w-10/12 w-full;
}

.courseBadgeContainer {
  @apply flex justify-end order-1 lg:order-2 lg:w-2/12;
}

.courseTitle {
  @apply text-xl align-top;
}

.courseSubtitle {
  @apply mt-2 text-gray-400 text-lg;
}

.courseExpired {
  @apply text-zur-red-100 uppercase ml-2 font-bold;
}

.buttonContainer {
  @apply mt-4;
}

.courseDetails {
  @apply text-gray-400 text-sm mt-4;

  > div:first-child {
    @apply flex justify-between md:justify-start gap-4;
  }

  > div:last-child {
    @apply mt-2;
  }
}

.courseDetailsName {
  @apply whitespace-nowrap;
}

.courseDetailsValue {
  @apply font-bold;
}

.componentCourse {
  .courseTitle {
    @apply text-base;
  }

  .courseSubtitle {
    @apply text-sm;
  }

  .buttonContainer {
    @apply mt-2;
  }

  .courseDetails {
    @apply text-xs mt-2;
  }
}
