.root {
  @apply flex justify-center items-center min-h-[56px] h-auto px-4 transition-all duration-700 bg-zur-light;
}

.carouselWrapper {
  @apply relative flex w-full max-w-lxl justify-center items-center;

  > a {
    @apply no-underline;
  }
}

.content {
  @apply flex items-center gap-2;
}

.title,
.text {
  @apply text-base lg:text-lg text-zur-primary;
}

.title {
  @apply font-bold line-clamp-2;
}

.text {
  @apply font-normal hidden lg:flex;
}

.badge {
  @apply whitespace-nowrap;
}

.controls {
  @apply hidden lg:flex absolute right-2;

  > button {
    @apply px-4;
  }
}
