.root {
  @apply flex flex-col lg:flex-row gap-8 justify-center;
}

.contentContainer {
  @apply w-full lg:w-8/12;
}

.accountSidebarContainer {
  @apply w-full lg:w-3/12 order-first lg:order-last;
}
