.root {
  @apply w-full flex justify-center lg:w-fit lg:inline-block px-6 py-3 shadow-none transition ease-in-out duration-200 rounded whitespace-nowrap no-underline font-normal leading-none text-base text-center;

  &[disabled] {
    @apply opacity-60;
  }
}

.hasIcon {
  @apply flex items-center;
}

/* Themes */
.primary {
  @apply bg-zur-primary text-white hover:shadow-lg;
}

.secondary {
  @apply bg-zur-secondary text-zur-primary hover:shadow-lg;
}

.secondaryOutline {
  @apply bg-white text-zur-orange-100 border border-zur-orange-100;
}

.light {
  @apply bg-zur-light text-zur-primary hover:shadow-lg;
}

.textButton {
  @apply bg-transparent text-zur-primary underline underline-offset-1;
}

.danger {
  @apply bg-zur-red-100 text-white hover:shadow-lg;
}

.dangerInverse {
  @apply bg-transparent text-zur-red-100 underline underline-offset-1;
}

/* Sizes */
.sm {
  @apply px-4 py-2 items-center text-sm;
}

.backButton {
  @apply w-auto -ml-5 mb-4 rounded-full items-center;
}
