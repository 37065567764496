.bundle {
  @apply mb-4 last:mb-8 border rounded-lg border-gray-200;
}

.bundleHeader {
  @apply flex flex-col lg:flex-row items-start gap-4 m-4;
}

.bundleTitle {
  @apply text-xl text-zur-primary order-2 lg:order-1 lg:w-10/12 w-full;
}

.bundleBadgeContainer {
  @apply flex justify-end order-1 lg:order-2 lg:w-2/12;
}

.bundleContent {
  @apply text-zur-primary;
}

.coursesHeading {
  @apply text-xl text-gray-400 my-2;
}

.courses {
  @apply ml-4 border border-r-0 rounded-l-lg;
}

.course {
  @apply flex flex-col lg:flex-row items-start gap-2 my-2;
}

.contentsContainer {
  @apply m-4;
}

.courseBadgeContainer {
  @apply flex lg:w-2/12;
}

.courseDetailsContainer {
  @apply w-full;
}

.courseTitle {
  @apply lg:w-10/12 hover:underline no-underline text-zur-primary font-normal;
}

.courseExpired {
  @apply text-zur-red-100 uppercase ml-2 font-bold;
}

.courseSubtitle {
  @apply mt-2 text-gray-400 text-lg;
}

.courseSubtitle {
  > span {
    @apply block;
  }
}
