.wordpressBarContainer {
  @apply bg-black py-2;
}

.wordpressBar {
  @apply col-span-12 px-1 text-center;
}

.wordpressBarMenuItem {
  @apply text-white font-normal whitespace-nowrap no-underline block;
}

.wordPresBarMenuItemDisabled {
  @apply text-gray-400;
}
