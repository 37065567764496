.wrapper {
  @apply rounded-2xl bg-zur-secondary-light px-5 py-10;
}

.heading {
  @apply text-2xl text-center font-bold mb-6;
}

.items {
  @apply list-none text-center;
}

.items li {
  @apply overflow-hidden relative pb-5;
}

.items li a {
  @apply text-zur-gray-200 no-underline;
}

.items li.isActive a {
  @apply text-zur-black-100 font-bold;
}

.items li.isActive:after {
  @apply bg-white box-content h-2 w-2 border-8;
}

.tokens {
  @apply text-center font-bold text-zur-primary;
}
