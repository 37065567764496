.htmlContent p,
.htmlContent ul,
.htmlContent ol,
.htmlContent blockquote {
  @apply my-6 text-zur-reading-texts text-[1.15rem] leading-normal break-words;
}

.htmlContent a {
  @apply underline;
}

.htmlContent ul,
.htmlContent ol {
  @apply pl-4;
}

.htmlContent ul {
  @apply list-disc;
}

.htmlContent ol {
  @apply list-decimal;
}

.htmlContent ul > li > ul,
.htmlContent ol > li > ol {
  @apply my-0 ml-4;
}

.htmlContent ul > li {
  @apply text-lg text-zur-reading-texts;

  > ul {
    list-style: circle;
  }
}

.htmlContent h2 {
  @apply font-bold text-4xl mt-12 mb-4 leading-snug text-zur-reading-texts;
}

.htmlContent h3 {
  @apply font-bold text-2xl mt-8 mb-4 leading-snug text-zur-reading-texts;
}

.htmlContent h4 {
  @apply font-bold text-xl mt-6 mb-4 leading-snug text-zur-reading-texts;
}

.htmlContent pre {
  @apply whitespace-pre overflow-x-auto p-4 text-sm leading-tight border border-gray-400 bg-gray-100;
}

.htmlContent code {
  @apply text-sm;
}

.htmlContent figcaption {
  @apply text-center text-lg;
}

.htmlContent blockquote {
  @apply bg-zur-secondary-light rounded-2xl my-8 p-8;

  > h4:first-child {
    @apply m-0;
  }

  > h4:not(:first-child) {
    @apply pt-8;
  }
}

.htmlContent blockquote p {
  @apply mt-0;
}

.htmlContent blockquote cite {
  @apply not-italic;
}

.htmlContent audio {
  @apply w-full;
}

.htmlContent iframe {
  @apply w-full;
}
