.root {
  @apply hidden md:block;

  > button {
    @apply w-full flex;
  }
}

.mainMenuItems {
  @apply absolute bg-gray-100 drop-shadow-xl rounded-lg mt-4 p-6 flex flex-col z-10;
}

.menuItem {
  @apply flex justify-between px-6 py-3 mb-2 shadow-none rounded whitespace-nowrap text-base no-underline font-normal leading-none text-zur-primary hover:bg-zur-primary hover:bg-opacity-20 ease-in-out duration-300;

  > a {
    @apply no-underline font-normal;
  }
}

.subMenu {
  @apply absolute inset-y-0 right-2;
}

.subMenuItems {
  @apply absolute inset-y-0 bg-white p-6 rounded-tr-lg rounded-br-lg;
}
