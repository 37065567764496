.root {
  @apply bg-yellow-50 border-yellow-400 p-8 rounded-2xl;
}

.body {
  @apply flex;
}

.iconWrap {
  @apply shrink-0;
}

.icon {
  @apply h-5 w-5 text-yellow-400;
}

.textWrap {
  @apply ml-3;
}

.text {
  @apply text-yellow-700;
}

.success {
  @apply bg-green-50 border-green-400;

  .icon {
    @apply text-green-400;
  }

  .text {
    @apply text-green-700;
  }
}

.danger {
  @apply bg-red-50 border-red-400;

  .icon {
    @apply text-red-400;
  }

  .text {
    @apply text-red-700;
  }
}

.info {
  @apply bg-gray-50 border-zur-blue-100;

  .icon {
    @apply text-zur-blue-100;
  }

  .text {
    @apply text-zur-blue-100;
  }
}
