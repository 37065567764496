.horizontalMenuContainer {
  @apply bg-zur-blue-200 py-2 hidden md:block;
}

.horizontalMenu {
  @apply col-span-12 flex justify-between items-center px-1;
}

.horizontalMenuItemContainer {
  @apply relative inline-block text-left;
}

.horizontalMenuItemContainerCanHide {
  @apply hidden lg:inline-block;
}

.horizontalMenuItem {
  @apply text-xl text-white font-normal whitespace-nowrap no-underline overflow-hidden;
}
