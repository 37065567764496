.root {
  @apply text-zur-primary;
}

.footerHead {
  @apply grid grid-cols-12 items-center;
}

.footerHeadText {
  @apply col-span-12 mt-6 md:mt-0 md:col-span-9 text-2xl;
}

.footerLogoImage {
  @apply col-span-12 md:col-span-3;
  max-width: 200px;
}

.footerMenu {
  @apply grid grid-cols-12 py-14;
}

.footerMenuColumn {
  @apply col-span-12 sm:col-span-6 md:col-span-3 my-6 md:my-0;
}

.footerMenuColumTitle {
  @apply font-bold uppercase mb-8;
}

.footerMenuColumLink {
  @apply font-normal;
}

.footerFoot {
  @apply container grid gap-4 grid-cols-12 items-end;
}

.copyRight {
  @apply col-span-12 md:col-span-9 order-2 md:order-1 text-xs;
}

.socialMenu {
  @apply col-span-12 md:col-span-3 order-1 md:order-2 md:text-right;
}

.socialLink {
  @apply px-2 md:pr-4 md:pl-0;
}

.socialIcon {
  @apply inline-block;

  height: 20px !important;
  width: 20px !important;
}
