.root {
  @apply flex w-full h-full;
}

.body {
  @apply flex flex-col w-full h-full m-0 p-0;
}

.contentContainer {
  @apply w-full flex-1;
}
