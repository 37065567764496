.sectionHeader {
  @apply py-1 text-zur-primary;
}

.sectionHeader.default {
  @apply bg-zur-secondary-light;
}

.sectionHeader.white {
  @apply bg-white;
}

.sectionHeaderHeading {
  @apply flex lg:w-8/12 justify-center mx-auto mb-4 font-bold text-4xl text-center;
}

.sectionHeaderHeroContent {
  @apply flex flex-col lg:w-8/12 justify-center mx-auto text-lg text-center;
  > p {
    @apply m-0;
  }
}
