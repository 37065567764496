.root {
  @apply flex justify-between items-center border-2 border-gray-100 rounded-xl px-2 py-0.5;
}

.searchIcon {
  @apply w-7 h-7 text-gray-200;
}

.searchInput {
  @apply text-base text-zur-primary border-0 shadow-none focus:ring-0 p-1 placeholder-current m-0;
}
