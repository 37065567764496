.mobile {
  @apply md:hidden;
}

.mobileBody {
  @apply fixed inset-0 z-40 flex;
}

.mobileOverlay {
  @apply fixed inset-0 bg-gray-600 bg-opacity-75;
}

.mobileContent {
  @apply relative min-w-full w-full bg-white flex-1 flex flex-col;
}

.mobileClose {
  @apply absolute top-4 right-4 p-1;
}

.mobileCloseButton {
  @apply h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-yellow-500;
}

.mobileCloseIcon {
  @apply h-6 w-6;
}

.mobileLogo {
  @apply shrink-0 px-4 flex items-center;
}

.mobileLogoImage {
  @apply w-32 h-auto;
}

.mobileMenu {
  @apply flex-1 h-0 p-4 overflow-y-auto;
}

.mobileItems {
  @apply flex flex-col pt-5;
}

.mobileItem {
  @apply text-lg w-full;

  &:not(:last-child) {
    @apply border-b;
  }
}

.mobileItemMenuWrapper {
  @apply p-4;

  > a {
    @apply no-underline font-medium;
  }
}

.mobileItemWrapper,
.mobileItemNoMenu {
  @apply block w-full no-underline font-medium;
}

.mobileItemMenu {
  @apply inline-flex items-center w-full pb-0 focus:outline-none font-medium text-zur-primary;
}

.mobileItemMenuOpen {
  @apply bg-zur-secondary-light;
}

.submenuItems {
  @apply pl-4 pt-2;
}

.submenuItem {
  @apply block py-2 no-underline font-medium;
  &:not(:last-child) {
    @apply border-b;
  }
}
