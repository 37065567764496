.formGroup:not(:global(.grow-0)) {
  @apply grow;
  flex-basis: 0;
}

.label {
  @apply block text-base font-medium text-gray-700;
}

.labelRequired {
  @apply text-red-500 ml-1;
}

.input,
.select {
  @apply mb-4 bg-white border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-lg rounded-md;
}

.input-success {
  @apply border-green-600;
}

.input-danger {
  @apply border-red-600;
}

.helpText {
  @apply mt-2 text-base text-gray-500;
}

.helpText-success {
  @apply text-green-600;
}

.helpText-danger {
  @apply text-red-600;
}

.fieldSet {
  @apply pt-4;
}

.fieldSetLabel {
  @apply font-bold text-lg mb-4 pb-4 border-b border-gray-200;
}

.fieldSetRow {
  @apply block space-x-0 md:flex md:space-x-4;
}
