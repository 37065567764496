.root {
  @apply uppercase italic font-bold rounded;
}

.xs {
  @apply text-sm;
}

.sm {
  @apply text-base;
}

.xs,
.sm {
  @apply px-1 py-0.5;
}

.lg {
  @apply text-lg px-2 py-1;
}

.new,
.discount,
.progress {
  @apply text-white bg-zur-orange-100;
}

.updated {
  @apply text-zur-primary bg-zur-secondary;
}

.info {
  @apply text-sm bg-zur-secondary;
}

.pending,
.ended {
  @apply bg-zur-light text-gray-400;
}

.completed {
  @apply bg-green-700 text-white;
}
