.root {
  @apply top-0 right-0 left-0 w-full shadow md:shadow-none bg-white/90 z-10 transition-all ease-in-out duration-700;
}

.mobileToggleWrapper {
  @apply flex;
}

.mobileToggle {
  @apply md:px-4 px-2 focus:outline-none focus:ring-2 focus:ring-inset md:hidden;
}

.mobileToggleIcon {
  @apply h-8 w-8 text-zur-primary;
}

.menu {
  @apply relative shrink-0;
}

.menuItems {
  @apply origin-top-left absolute right-0 mt-6 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10 bg-gray-100 rounded-lg border-0 p-6;
}

.dropdownItem {
  @apply inline-flex justify-center items-center w-full px-4 py-2 text-white focus:outline-none focus:ring-2;

  &:hover {
    @apply text-zur-orange-100;
  }
}

.menuItem {
  @apply block px-4 py-2 text-gray-700;

  &:hover {
    @apply bg-gray-700 text-zur-orange-100;
  }
}

.menuItemActive {
  @apply bg-gray-100 text-gray-900;
}

.headerContainer {
  @apply container mx-auto pr-2 lg:px-5 max-w-lxl;
}

.logoRowContent {
  @apply flex items-center py-2 md:py-4 justify-between;

  > div {
    @apply mx-4 first-of-type:ml-0;
  }
}

.logoRowLeft {
  @apply flex items-center;
}

.logoRowImage {
  @apply md:mr-10;
  width: 90px;
  height: 42px;
  @screen md {
    width: 166px;
    height: 72px;
  }
}

.logoRowMenu {
  @apply flex items-center justify-between my-0 z-10;

  > li {
    @apply ml-4 whitespace-nowrap;
    a {
      @apply no-underline font-normal;
    }
  }
}

.logoRowSearch {
  max-width: 25px;

  @screen md {
    max-width: 17px;
  }
}

.headerNavigation {
  @apply md:block hidden bg-black py-3;
  /* prevent the menu height from bouncing when items load */
  min-height: theme("spacing.16");

  a {
    color: #ffffff;
  }
}

.myProfile {
  @apply inline-flex justify-center items-center w-full text-zur-primary whitespace-nowrap;
}

.myProfileMenuItem {
  @apply flex justify-between px-6 py-3 mb-2 shadow-none rounded whitespace-nowrap text-base no-underline font-normal leading-none text-zur-primary hover:bg-zur-primary hover:bg-opacity-20 ease-in-out duration-300;
}

.myProfileMenuTokens {
  @apply flex items-center gap-2 px-6 py-3 mb-2 bg-white rounded-lg font-bold;
}

.cartIconWrapper {
  @apply flex items-center no-underline;
}

.cartIcon {
  @apply w-6 h-6;
}

.horizontalMenu {
  @apply inline-flex justify-center items-center w-full text-zur-primary whitespace-nowrap;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.horizontalMenuItems {
  @apply origin-top-left absolute mt-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10 bg-gray-100 rounded-lg border-0 p-6;
}
