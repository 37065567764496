.loaderContainer {
  @apply relative h-24 w-24 m-4;
}

.loadingContainerCenter {
  @apply mx-auto;
}

.loadingContainerFullPage {
  @apply w-full m-0;
  min-height: 100vh;
}

.loaderContent {
  @apply absolute inset-0 m-auto;
  @apply h-14 w-24;
}

.loadingContainerFullPage .loaderContent {
  @apply mx-auto mt-48;
}

.loaderLineWrapper {
  @apply absolute overflow-hidden top-1 bottom-1 inset-x-0;
  animation: spin 2000ms cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
  transform-origin: 50% 100%;
}

.loaderLine {
  @apply absolute inset-0 my-0 mx-auto rounded-full;
  @apply h-24 w-24 border-4;
  @apply opacity-75;
}

.loaderLineWrapper:nth-child(1) {
  animation-delay: -80ms;
}
.loaderLineWrapper:nth-child(2) {
  animation-delay: -150ms;
}
.loaderLineWrapper:nth-child(3) {
  animation-delay: -175ms;
}
.loaderLineWrapper:nth-child(4) {
  animation-delay: -250ms;
}
.loaderLineWrapper:nth-child(5) {
  animation-delay: -350ms;
}

.loaderLineWrapper:nth-child(1) .loaderLine {
  border-color: #e66154;
  height: 90px;
  width: 90px;
  top: 7px;
}
.loaderLineWrapper:nth-child(2) .loaderLine {
  border-color: #f17a22;
  height: 76px;
  width: 76px;
  top: 14px;
}
.loaderLineWrapper:nth-child(3) .loaderLine {
  border-color: #f3a721;
  height: 62px;
  width: 62px;
  top: 21px;
}
.loaderLineWrapper:nth-child(4) .loaderLine {
  border-color: #f2db63;
  height: 48px;
  width: 48px;
  top: 28px;
}
.loaderLineWrapper:nth-child(5) .loaderLine {
  border-color: #f0ea78;
  height: 34px;
  width: 34px;
  top: 35px;
}

@keyframes spin {
  0%,
  20% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
